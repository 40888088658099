import { getServerHelpers, getUniversalHelpers } from "../../helpers";

import express from "express";
import _ from "lodash";
import moment from "moment-timezone";
import { ObjectKeys, SendMessageToSlackChannel, isProduction } from "../../utils";
import {
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgPaymentInvoiceCredit,
  OrgPaymentType,
  PlayerBundle__AccountType
} from "@ollie-sports/models";
import { triggerForPaymentNotifications } from "../notification/payment-helpers";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function orgPayment__server__sendScheduledOrgPaymentReminders() {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();

  const startDate = moment().startOf("hour").add(1, "day").valueOf();
  const endDate = moment().endOf("hour").add(1, "day").valueOf();

  olliePipe.emitEvent({
    type: "metric-send-payment-reminders",
    payload: { type: "start", startDate, endDate }
  });

  try {
    const scheduledOrgInvoicePaymentPlanInstallmentsData = await h.OrgInvoice.query({
      where: [
        {
          autoChargeDateMS: [">=", startDate]
        },
        {
          autoChargeDateMS: ["<", endDate]
        }
      ],
      limit: 5000
    });

    const scheduledOrgInvoicePaymentPlanInstallments = (
      scheduledOrgInvoicePaymentPlanInstallmentsData.docs as OrgInvoiceChild[]
    ).filter(oi => !oi.thisInvoicePaidInFullDateMS);
    olliePipe.emitEvent({
      type: "metric-send-payment-reminders",
      payload: { type: "got-data", orgInvoiceIds: scheduledOrgInvoicePaymentPlanInstallments.map(a => a.id) }
    });

    if (scheduledOrgInvoicePaymentPlanInstallments.length) {
      olliePipe.emitEvent({
        type: "metric-send-soon-to-be-due-payment-reminders",
        payload: { type: "success", orgInvoiceIds: scheduledOrgInvoicePaymentPlanInstallments.map(a => a.id) }
      });
    }

    const orgInvoiceData = _.compact(
      await Promise.all(
        [...scheduledOrgInvoicePaymentPlanInstallments].map(async orgInvoice => {
          const [parentOrgInvoice, orgPaymentInvoiceCreditData] = await Promise.all([
            h.OrgInvoice.getDoc(orgInvoice.parentOrgInvoiceId),
            h.OrgPayment.query({ where: [{ type: ["==", OrgPaymentType.invoiceCredit] }, { invoiceId: ["==", orgInvoice.id] }] })
          ]);
          if (!parentOrgInvoice) {
            return null;
          }
          return {
            orgInvoiceChild: orgInvoice,
            orgInvoiceParent: parentOrgInvoice as OrgInvoiceParent,
            orgPaymentInvoiceCredits: orgPaymentInvoiceCreditData.docs as OrgPaymentInvoiceCredit[]
          };
        })
      )
    );
    await triggerForPaymentNotifications({
      type: "soonToBeCharged",
      orgInvoiceData
    });
  } catch (e: any) {
    console.error(e);
    await olliePipe__server__sendOlliePipeEvent({
      type: "error-send-scheduled-payment-reminders",
      payload: e
    });
  }
  // SERVER_ONLY_TOGGLE
}

orgPayment__server__sendScheduledOrgPaymentReminders.auth = async (r: express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called directly via cron jobs in production!");
  }
};

// i18n certified - complete
