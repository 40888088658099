import {
  OrgId,
  OrgInvoiceId,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgInvoice__ManualPaymentPlanInstallment,
  OrgInvoice__RegistrationPaymentPlanInstallment
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { SendMessageToSlackChannel, generateOrgInvoiceId } from "../../utils";
import { triggerForPaymentNotifications } from "../notification/payment-helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function orgPayment__server__addInstallment(p: {
  parentOrgInvoiceId: OrgInvoiceId;
  orgId: OrgId;
  newInstallment:
    | Omit<OrgInvoice__ManualPaymentPlanInstallment, "id" | "createdAtMS">
    | Omit<OrgInvoice__RegistrationPaymentPlanInstallment, "id" | "createdAtMS">;
}): Promise<{ status: "success" | "error" }> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const nowMS = Date.now();
  const parentInvoice = await h.OrgInvoice.getDoc(p.parentOrgInvoiceId);
  if (
    !parentInvoice ||
    !(parentInvoice.type === OrgInvoiceTypes.manual || parentInvoice.type === OrgInvoiceTypes.registration) ||
    (parentInvoice as OrgInvoiceParent).derivedTotalAmountPaidCentsIncludingChildrenInvoices ===
      (parentInvoice as OrgInvoiceParent).derivedTotalAmountDueCentsIncludingChildrenInvoices
  ) {
    return { status: "error" };
  }
  const newInstallment = {
    id: generateOrgInvoiceId(),
    createdAtMS: nowMS,
    ...p.newInstallment
  };
  try {
    await h._BatchRunner.executeBatch([
      await h.OrgInvoice.add(
        {
          doc: newInstallment
        },
        { returnBatchTask: true }
      ),
      await h.OrgInvoice.update(
        {
          id: parentInvoice.id,
          doc: {
            derivedTotalAmountDueCentsIncludingChildrenInvoices:
              parentInvoice.derivedTotalAmountDueCentsIncludingChildrenInvoices + p.newInstallment.amountDueCents
          }
        },
        { returnBatchTask: true }
      )
    ]);
    await triggerForPaymentNotifications({
      type: "newInstallmentAmount",
      orgInvoice: newInstallment
    }).catch(e => {
      olliePipe__server__sendOlliePipeEvent({
        type: "error-add-installment-notification",
        payload: e,
        slackImportantErrorsMessage: `Error running error-add-installment-notification. ${JSON.stringify(
          {
            message: e.message,
            stack: e.stack
          },
          null,
          2
        )}`
      });
    });
    return { status: "success" };
  } catch (e) {
    return { status: "error" };
  }
}
orgPayment__server__addInstallment.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateToken(r);
};

// i18n certified - complete
